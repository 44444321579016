<template>
	<b-card >
		<div class="card-header">
			<h4 class="card-title">Realizar Reserva </h4>
			<div>
				<feather-icon icon="MoonIcon" size="16" />
					<!-- :fill="'#ffd600'" -->
				<b>  Noches: </b>  {{searchForm.nights}} |
				<feather-icon icon="SearchIcon" size="16" />
					<!-- :fill="'#333366'" -->
				<b> Resultados:  </b> {{resultados}}
			</div>
		</div>
		<b-card-body>
			<validation-observer ref="formSearch" tag="form" v-if="showForm">
				<b-form>
					<b-row>
						<b-col md="3">
							<validation-provider  name="Hotel" rules="required" >
								<b-form-group slot-scope="{ errors }">
									<label>Seleccione hotel*</label>
									<b-form-select
										:state="errors.length > 0 ? false:null"
										@change="setHotel"
										v-model="searchForm.idHotel"
										:disabled="isLoadingBookings"
									>
										<option value="">Seleccione hotel</option>
										<option v-for="hotel in filteredHotels" :key="hotel.id" :value="hotel.id">{{hotel.name}}</option>
									</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
							</validation-provider>
						</b-col>

						<b-col md="3">
							<b-form-group >
								<label><strong>Check in - Check out*</strong></label>
								<flat-pickr
									v-model="rangeDate"
									class="form-control"
									:config="{ mode: 'range', minDate: 'today'}"
									@input="setRangeDaysAndTotalNights"
									:disabled="isLoadingBookings"
								/>
							</b-form-group>
						</b-col>

						<b-col md="1">
							<b-form-group>
								<validation-provider name="Adultos" rules="required">
								<b-form-group slot-scope="{ errors }">
									<label>Adultos*</label>
									<b-form-select
										v-model="searchForm.adults"
										:state="errors.length > 0 ? false:null"
										@input="setAdults"
										:disabled="isLoadingBookings"
									>
										<option v-for="(adulto, index) in adultOptions" :key="index" :value="adulto.value">{{adulto.title}}</option>
									</b-form-select>
									<b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
								</b-form-group>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="1">
							<b-form-group>
								<validation-provider #default="{ errors }" name="Niños" rules="required">
								<label>Niños*</label>
								<b-form-select
									v-model="searchForm.numberChildren"
									:options="childrenOptions"
									:state="errors.length > 0 ? false:null"
									@change="setChilds"
									:disabled="isLoadingBookings"
								></b-form-select>
								<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>

						<b-col md="3">
							<b-form-group>
								<label>Código promocional</label>
								<b-form-input
									v-model="searchForm.promoCode"
									@change="setPromocode"
									:disabled="isLoadingBookings"
								/>
							</b-form-group>
						</b-col>

						<b-col md="2" v-for="(child,index) in searchForm.ageChildren" :key="index">
							<label :for="'AgeChild'+index">Edad niño {{index+1}}*</label>
							<b-input-group v-if="!isLoading">
								<b-form-select
									:id="'AgeChild'+index"
									v-model="searchForm.ageChildren[index].edad"
									@change="setAgeNinio(searchForm.ageChildren[index])"
									:disabled="isLoadingBookings"
								>
									<option v-for="(edad, index) in ageChildrenOptions" :key="index" :value="edad.value">{{edad.text}} </option>
								</b-form-select>
								<b-input-group-append>
									<b-button variant="danger" size="sm" @click="deleteAgeChild(child.key)" :disabled="isLoadingBookings" >
										<b-icon icon="trash-fill" ></b-icon>
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-col>
						<b-col cols="12" class="mt-2">
							<b-button variant="danger" @click.prevent="resetTab" :disabled="isLoadingBookings">
								<feather-icon
									icon="Trash2Icon"
									size="16"
								/> Limpiar
							</b-button>
							<div class="float-right">
								<b-button
									variant="primary"
									type="submit"
									@click.prevent="handleSearch"
									:disabled="isLoadingBookings"
								>
									<b-spinner small label="Loading..." variant="info" v-if="isLoadingBookings" /> Buscar
									<feather-icon
										v-if="!isLoadingBookings"
										icon="SearchIcon"
										size="16"
									/>
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-form>
			</validation-observer>

		</b-card-body>
		<!-- form -->
	</b-card>
</template>

<script>
import * as moment from 'moment'
import BCardCode from '@core/components/b-card-code'
import flatPickr from 'vue-flatpickr-component'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { adultOptions, vendorOptions, rateOptions, languageOptions, childrenOptions, ageChildrenOptions } from '@/data/data/'
import { showAlertMessage, stringAleatorio, toJson, sortalphabetically } from '@/helpers/helpers'

export default {
	props: {
		searchForm:{
			type: Object,
			required: true
		},
		isLoadingBookings:{
			type: Boolean,
			required: true
		},
		resultados:{
			type: Number,
			required: true
		}
	},
	components: {
		BCardCode,
		// vSelect,
		flatPickr
	},
	data() {
		return {
			isLoading: false,
			vendorOptions,
			rateOptions,
			languageOptions,
			adultOptions,
			childrenOptions,
			ageChildrenOptions,
			showForm: true,
			rangeDate: this.searchForm.rangeDate || null,
		}
	},
	computed:{
		...mapState('start',['hotels','promoCodes']),
		...mapState('bookings',['tabs', 'myTabs','searchForms','rooms']),
		...mapState('auth',['user']),
		...mapGetters('bookings',['activeTabData']),
		filteredHotels(){
			return sortalphabetically(this.hotels.filter(hotel => hotel.dingusCode != null))
		},
	},
	methods: {
		...mapActions('bookings',['fetchRooms']),
		...mapMutations('bookings',['addArrayNinios','setAgeChildForm','deleteAgeChildSearch','resetToInitialStateTab','setBookings','activateTab','setHotelSearch','setRangeDateNightsSearch','setAdultsSearch','setChildsSearch','setPromocodeSearch','resetTabActive','replaceDataSearchFom','setRooms']),
		...mapMutations('shop',['setHotelCliente']),
		setHotel(idHotel){
			const payload = { idHotel, fromTab: this.searchForm.fromTab, vendorcode: this.user?.canalventa?.dingusvendorcode || ''}
			this.setHotelCliente(idHotel)	//muto tambien para el cliente
			this.setHotelSearch(payload)
			this.clearRooms()
		},
		handleSearch(){
			const el = document.getElementById('footer')
			const searchForm = toJson( this.searchForms.find( form => form.fromTab === this.searchForm.fromTab ) )
			const { rangeDate, dateIn, dateOut, nights } = searchForm
			el.scrollIntoView({behavior: "smooth"})
			this.$refs.formSearch.validate().then(success => {
				if (success) {
					if(rangeDate != '' && dateIn != '' && dateOut != '' && nights > 0 ){
						this.getRooms()
					} else {
						showAlertMessage(`Fecha: Check in - Check out`, 'InfoIcon', `😰😰 Falta que se indique valor para Check in - Check out`,'danger', 3000, 'bottom-right')
					}
				}
			})
		},
		async getRooms(){
			/** 2022-06-14 to 2022-06-21 */
			this.$emit('set-is-loading-bookings', true)
			const searchForm = toJson( this.searchForms.find( form => form.fromTab === this.searchForm.fromTab ) )
			const { fromTab  } = searchForm
			const hotelData = this.hotels.find( hotel => hotel.id === searchForm.idHotel)
			const previousRooms = toJson( this.rooms.filter( rooms => rooms.fromTab !== fromTab ) )

			const payload = {
				...searchForm,
				tarifa: '',
				rateplanid: "",
				isManualRate: false,
				manualRate: "",
				rate: "",
				idioma: searchForm.language || 'en' ,
				roomCode: '',
				roomType:0,
				hotel: hotelData.dingusCode || null,
				children: searchForm.numberChildren,
				ninos: searchForm.ageChildren,
				promotionCode: searchForm.promoCode || '',
			}
			this.replaceDataSearchFom(payload)
			//  console.log( payload )
			const rooms = await this.fetchRooms( payload ) //obtengo lista de rooms
			const payloadRooms = [...previousRooms, ...rooms] // rooms anteriores mas los nuevos
			this.$emit('set-is-loading-bookings', false)
			this.setRooms( payloadRooms ) //pongo los rooms en la lista

		},
		setRangeDaysAndTotalNights( rangeDate ){
			if( rangeDate.includes("to") ){
				const splitdate = rangeDate.split(' to ')
				const dateIn = splitdate[0] || moment(new Date()).format('YYYY-MM-DD')
				const dateOut = splitdate[1] || moment(new Date()).add(1, 'days').format('YYYY-MM-DD')
				const inicio = moment(splitdate[0])
				const fin = moment(splitdate[1])
				const nights = ( splitdate[0] && splitdate[1] ) ? fin.diff(inicio, 'days') : 0
				const payload = { dateIn, dateOut, nights, rangeDate, fromTab: this.searchForm.fromTab }
				this.setRangeDateNightsSearch( payload )
			} else {
				const payload = { dateIn:'', dateOut:'', nights: 0, rangeDate: null, fromTab: this.searchForm.fromTab }
				this.setRangeDateNightsSearch( payload )
			}
			this.clearRooms()
		},
		setAdults( adults){
			const payload = { adults, fromTab: this.searchForm.fromTab}
			this.setAdultsSearch(payload)
			this.clearRooms()
		},
		setChilds( numberChilds ){
			let ageChildrens = []
			for (let i = 0;  i < numberChilds; i++) {
				ageChildrens.push({edad: 1, key: stringAleatorio() })
			}
			const payload = { numberChilds, ageChildrens, fromTab: this.searchForm.fromTab }
			this.setChildsSearch(payload)
			this.clearRooms()
		},
		setAgeNinio(params){
			const { key, edad } = params
			const payload = { keyAge: key, fromTab: this.searchForm.fromTab, edad }
			this.setAgeChildForm(payload)
			this.clearRooms()
		},
		deleteAgeChild(keyAge){
			this.isLoading = true
			const payload = { fromTab: this.searchForm.fromTab, keyAge}
			this.deleteAgeChildSearch(payload)
			this.isLoading = false
			this.clearRooms()
		},
		setPromocode(promoCode){
			const payload = { promoCode, fromTab: this.searchForm.fromTab}
			this.setPromocodeSearch(payload)
			this.clearRooms()
		},
		resetTab(){
			this.showForm = false
			this.rangeDate = null
			this.resetTabActive({fromTab: this.searchForm.fromTab})
			this.showForm = true
			this.$refs.formSearch.reset()
		},
		clearRooms(){
			const keySearchFrom = this.searchForm.fromTab
			const previousRooms = this.rooms.filter( room => room.fromTab !== keySearchFrom ) // descarto los rooms que corresponde a este tab
			const newRooms = []
			const payloadRooms = [...previousRooms, ...newRooms]
			this.setRooms( payloadRooms ) //muto el state de rooms con los que ya había
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
