<template>
	<div>
		<div class="float-right">			
			<b-button
				v-if="myTabs.length <= 4"
				v-ripple.400="'rgba(113, 102, 240, 0.15)'"
				v-b-tooltip.hover.v-primary
				title="Nuevo tab"
				variant="primary"
				size="sm"
				@click.prevent="addTab"
				>
				<b-icon icon="plus" style="font-size:1.5em"></b-icon>
			</b-button>
		</div>
		<b-tabs>
			<b-tab
				v-for="(item, index) in myTabs"
				:key="'dyn-tab-' + item.keyTab"				
				@click="setActiveTab(item.keyTab)"
				:active="item.isActive"
			>
				<!-- :active="item.isActive" -->
			<template #title >
				<span class="mr-1">Reserva {{index+1}}</span>	
				<div v-if="!item.isMain" @click="deleteTab(item.keyTab)" >
					<feather-icon
						v-b-tooltip.hover.v-danger
						title="Cerrar tab"
						icon="XIcon"
						style="color: #b71c1c;"
						
					/>
				</div>																				
			</template>
				<div v-for="(searchForm) in searchForms" :key="searchForm.fromTab">
					<SearchBooking
						v-if="item.keyTab == searchForm.fromTab"
						:searchForm="searchForm"
						:resultados="roomsFromTab(item.keyTab)"
						:isLoadingBookings="isLoadingBookings"
						@set-is-loading-bookings="setIsLoadingBookings"
					/>
				</div>
				
				
				<div class="text-center" v-if="isLoadingBookings">							
					<b-spinner label="Loading..." variant="success"/><br>
					<strong>Buscando información</strong>
				</div>
				<div v-else-if="rooms.length">
					<div v-for="(room) in rooms" :key="room.keyRoom">
						<b-card style="max-width: 100%;"  v-if="item.keyTab == room.fromTab">
							<div >
								<Room :room="room"/>
							</div>
						</b-card>	
					</div>
				</div>
						
				<b-alert show variant="danger" v-if="roomsFromTab(item.keyTab) === 0 && !isLoadingBookings" class="mt-2">
					<div class="alert-body text-center"> 😣 <span>No se han encontrado resultados para tu búsqueda</span></div>
				</b-alert>
				
			</b-tab>
			
			<!-- Render this if no tabs -->
			<template #empty>
				<div class="text-center text-muted">
				There are no open tabs<br>
				Open a new tab using the <b>+</b> button above.
				</div>
			</template>			
		</b-tabs>
	</div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'

import SearchBooking from '@/modules/shop/components/bookings/SearchBooking'
import Room from '@/modules/shop/components/bookings/Room'
import { stringAleatorio } from '@/helpers/helpers'


import Ripple from 'vue-ripple-directive'

export default {
	components: {		
		SearchBooking,
		Room		
	},
	async mounted(){		
		await this.getInitialContentBooking()		
	},
	directives: {
		Ripple,
	},
	data(){
		return {		
			isLoadingBookings: false
		}
	},
	computed:{
		...mapState('bookings',['myTabs','searchForms', 'rooms']),
		...mapState('start',['hotels','promoCodes']),             
		...mapState('auth',['user']), 
	},
	methods: {		
		...mapActions('bookings',['fetchBookings','getInitialContentBooking']),
		...mapMutations('bookings',['addNewTab','removeTab','activateTab']),
		roomsFromTab(keyTab){
			return this.rooms.filter( room => room.fromTab === keyTab ).length // descarto los rooms que corresponde a este tab
		},
		addTab(){
			const tab = {
				keyTab: stringAleatorio(),
				isActive: true,
				isMain: false
        	}
			const searchForm = {
				adults: 1, 
				ageChildren:[],                
				dateIn: '',
				dateOut: '',
				fromTab: tab.keyTab,
				hotel: '',
				language: 'en',
				nights: 0,
				numberChildren: 0, 
				promoCode:'',
				rangeDate: '',
				vendorcode: this.user.canalventa.dingusvendorcode || '',
			}
			this.addNewTab({tab, searchForm})
		},
		deleteTab(keyTab){
			this.removeTab(keyTab)
		},
		setActiveTab(key){
			this.activateTab(key)
		},
		setIsLoadingBookings (bool){
			this.isLoadingBookings = bool
		}	
	},
}

</script>