<template>
	<div>
		<b-row>
			<div class="col-xs-6 col-md-3 col-sm-12">
				<ImgRoom :room="room"/>
			</div>
			<div class="col-xs-6 col-md-5 col-sm-12">
				<b-card-body>
					<h6 class="item-name mb-1">
						<b class="text-body"> {{ room.Name }} </b>
					</h6>
					<Rates
						v-if="!isLoadingRates"						
						:room="room"					
					/>
					<div class="text-center" v-if="isLoadingRates">							
						<b-spinner label="Loading..." variant="success"/><br>
						<strong>Buscando tarifas</strong>
					</div>

					<b-card-text class="long-description text-justify">
						{{ room.DescriptiveText }}
					</b-card-text>
					<AdditionalDataRoom :room="room" />				

				</b-card-body>
			</div>
			<div class="col-xs-6 col-md-4 col-sm-12" >
				
				<PriceMatchOptions
					:room="room"
					:isLoadingRates="isLoadingRates"
					@set-is-loading-rates="setIsLoadingRates"
				/>                                            

			</div>
		</b-row>
	</div>
</template>

<script>
import ImgRoom from '@/modules/shop/components/bookings/ImgRoom'
import Rates from '@/modules/shop/components/bookings/Rates'
import AdditionalDataRoom from '@/modules/shop/components/bookings/AdditionalDataRoom'
import PriceMatchOptions from '@/modules/shop/components/bookings/PriceMatchOptions'


export default {
	props: {
		room:{
			type: Object,
			required: true
		}			
	},
	components: {		
		ImgRoom,
		Rates,
		AdditionalDataRoom,
		PriceMatchOptions
	},
	data(){
		return{
			isLoadingRates: false
		}
	},
	methods:{
		setIsLoadingRates(boolean){            
            this.isLoadingRates = boolean
        }
	}
}
</script>

