<template>
	<div>
		<b-carousel :interval="0" controls indicators v-if="room.RoomImg" >						
			<b-carousel-slide v-for="(img, index) in room.multimedias" :key="index" >
				<template #img>
					<b-card-img	:src="img.multimedia" height="320" />
				</template>
			</b-carousel-slide>
		</b-carousel>
		<b-card-img v-else style="height:100%;" :src="imgRoom"/>
	</div>
</template>

<script>
export default {
	props: {
		room:{
			type: Object,
			required: true
		}			
	},
	data(){
		return {			    			
			imgUrl: `https://backsales.thefives.com.mx/media/imgdefault.jpg`, //temporal
		}
  	},
	computed:{
		imgRoom(){
			return this.room.multimedias.length ? this.room.multimedias[0].multimedia : this.imgUrl
		}
	}
}
</script>

