<template>
  <div>
    <b-row class="mb-2">
      <b-col>
        <div class="float-right">
          <b-form-checkbox
            v-model="isRateLimit"
            :value="true"
            class="custom-control-success"
          >
            Es tarifa límite
          </b-form-checkbox>
        </div>
        <div>
          <strong>Tarifa inicial:</strong>
          <b-badge variant="warning" :class="room.detailSelected.priceMatchTax != ''? 'underline-price':''">
            ${{room.priceInit}}
          </b-badge>
        </div>
        <div v-if="room.detailSelected.PromotionName != ''">
          <strong>Promoción: </strong>{{room.detailSelected.PromotionName}}
        </div>

        <div v-if="room.showingRates">
          <strong>Tarifa Manual:</strong> ${{room.detailSelected.manualPrice}}
        </div>
        <!-- <div v-if="room.detailSelected.priceMatchTax != ''">
          <strong>Tarifa con promoción: </strong>${{room.detailSelected.priceMatchTax }}
        </div> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group  :label="'Divisa: '+ room.detailSelected.divisa.code">
          <b-form-select v-model="room.detailSelected.divisa">
            <option v-for="cur in currencies" :key="cur.id" :value="cur">{{cur.code}}</option>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group label="Tarifa" v-if="!isLoadingRates">
          <b-form-input
            placeholder="Tarifa"
            type="text"
            @keypress="onlyNumber"
            @change="setManualPrice"
            v-model="room.detailSelected.manualPrice"
          />
        </b-form-group>
        <div class="text-center" v-if="isLoadingRates">
            <b-spinner label="Loading..." variant="success"/><br>
            <strong>Buscando tarifa</strong>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-badge variant="warning" class="custom-margin ml-0" v-if="!!room.detailSelected.divisa && room.detailSelected.divisa.currencyid != 1">
          TC: ${{exchangeRate}}
        </b-badge>
        <b-badge variant="success" class="custom-margin ml-0">
          <feather-icon
            icon="DollarSignIcon"
            class="mr-25"
          />
          <span>{{precioConversion}} </span>
        </b-badge>
      </b-col>
    <b-col>
      <div class="float-right">
        <b-button
          :class="room.detailSelected.PromotionName != '' ? 'mr-1':''"
          variant="primary"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          :disabled="room.detailSelected.manualPrice == '' || !room.showingRates"
          @click="checkPriceMatchByManualRate"
          title="Buscar por tarifa manual"
          ><feather-icon icon="SearchIcon"/>
        </b-button>
          <!-- v-b-tooltip.hover.left="'Buscar por tarifa manual'" -->

        <b-button
          v-if="room.detailSelected.PromotionName != '' && !isRateLimit"
          variant="primary"
          @click="handlerAddCart"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-tooltip.hover.top="'Autorizar tarifa manual'"
        > <feather-icon icon="UnlockIcon"/> Autorizar</b-button>
        <b-button
          v-if="room.detailSelected.PromotionName != '' && isRateLimit"
          variant="primary"
          @click="handlerAddCart"
          :disabled="!room.showingRates"
          >
          <feather-icon
            icon="ShoppingCartIcon"
            size="16"
          /> Añadir
        </b-button>
      </div>

    </b-col>
    </b-row>
    <AuthorizeBooking
      :room="room"
      @set-payload-add-to-cart="handlerAddToCart"
    />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { toJson, toDecimal,stringAleatorio, currentDate } from '@/helpers/helpers'
import { VBTooltip } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AuthorizeBooking from '@/modules/shop/components/bookings/AuthorizeBooking'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props:{
    room:{
      type: Object,
      required: true,
    },
    isLoadingRates:{
      type: Boolean,
      required: true,
    }
  },
  components: {
    AuthorizeBooking
  },
  data() {
		return {
      isRateLimit: false,
		}
	},
  computed:{
		...mapState('start',['promoCodes', 'currencies']),
    ...mapState('bookings',['searchForms','userAuthorizeRate']),
    ...mapState('auth',['user']),
    precioConversion(){
      const precioToUse = (this.room.detailSelected.priceMatchTax != '' ) ? this.room.detailSelected.priceMatchTax : this.room.detailSelected.AmountAfterTax
      const conversion = precioToUse * this.room.detailSelected.divisa.value
      return toDecimal(conversion)
    },
    exchangeRate(){
      return (Math.round( (this.room.detailSelected.divisa.value) * 100) / 100).toFixed(2)
    }
  },
  methods:{
    ...mapMutations('bookings',['setManualPriceSelectedRate']),
    setManualPrice(){
     const keyRoom = this.room.keyRoom
     const manualRate = this.room.detailSelected.manualPrice
     const payload = { keyRoom, manualRate }
     this.setManualPriceSelectedRate(payload)
    },
    onlyNumber(event){
			let keyCode = (event.keyCode ? event.keyCode : event.which)
			if ((keyCode < 48 || keyCode > 57) ) { // 46 is dot
				event.preventDefault()
			}
		},
    checkPriceMatchByManualRate(){
      const searchForm = toJson(this.searchForms.find( item => item.fromTab === this.room.fromTab ))
      const { hotel, idioma, dateIn, dateOut, ninos, adults, children, vendorcode } = searchForm
      const { RoomTypeCode, keyRoom, priceInit } = this.room
      const manualRateFormat = parseFloat( this.room.detailSelected.manualPrice )
      const priceInitFormat = parseFloat( priceInit )

        this.room.detailSelected.allowPriceMatch = true  //muestro tab para price match manual rate
        const payload = {
          adults,
          children,
          dateIn,
          dateOut,
          hotel,
          idioma,
          isManualRate: true,
          keyRoom, //lo ocupo de manera interna
				  manualRate: manualRateFormat, //requirido para price match manual price
          ninos,
          priceInit, //lo ocupo de manera interna
          promotionCode: '', //vacío
          rate: priceInitFormat, //requirido para price match manual price
          rateplanid: "",
          roomCode: RoomTypeCode,
          tarifa: this.room.detailSelected.RatePlanCode,
          vendorcode
        }
      this.$emit('check-price-match-by-promotion-code', payload )
    },
    handlerAddCart(){
      if( this.isRateLimit ){
        const payload = {
          authorizationUser: null,
          dateAuthorization: null,
          isAuthorization: false,
          isRateLimit: true,
          requiresAuthorization: false,
          urlAuthorization: null,
        }
      this.$emit('handler-add-to-cart', payload)
      }
      if(!this.isRateLimit){
        this.openModal()
      }
    },
    handlerAddToCart(){
      const { idUser, urlAuthorization } = this.userAuthorizeRate
      this.closeModal()
      const payload = {
        authorizationUser: idUser,
        dateAuthorization: currentDate(),
        isAuthorization: true,
        isRateLimit: false,
        requiresAuthorization: false,
        urlAuthorization
      }
      this.$emit('handler-add-to-cart', payload)
    },
    openModal(){
			this.$root.$emit('bv::show::modal', 'authorize-rate'+this.room.keyRoom)
    },
    closeModal(){
			this.$root.$emit('bv::hide::modal', 'authorize-rate'+this.room.keyRoom)
    }
  }
}
</script>
